import React from "react"

import Layout from "../components/layout"
import styled from "styled-components"

const H2Element = styled.h2`
  margin-top: 30vh;
  display: flex;
  justify-content: center;
  color: white;
`

const NotFoundPage = () => (
  <Layout>
    <H2Element>Nie znaleziono.</H2Element>
  </Layout>
)

export default NotFoundPage
